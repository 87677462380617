































import Vue from 'vue';
import Component from 'vue-class-component';
const twemoji = require('twemoji');

@Component
export default class HeadSection extends Vue {
    parse(emoji: string) {
        return twemoji.default.parse(emoji);
    }
}
