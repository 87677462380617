































































































import Vue from 'vue';
import Component from 'vue-class-component';
const twemoji = require('twemoji');

@Component
export default class Features extends Vue {
    fadeItems?: Array<Element>;

    handleScroll() {
        let body = document.body,
            html = document.documentElement;

        let height = Math.max( body.scrollHeight, body.offsetHeight, 
                              html.clientHeight, html.scrollHeight, html.offsetHeight );
        let scrollPadding = window.innerHeight * 0.75;
        if (this.fadeItems === undefined || this.fadeItems.length === 0) {
            this.loadFadeItems();
            if (this.fadeItems === undefined || this.fadeItems.length === 0) {
              window.removeEventListener('scroll', this.handleScroll);
              return;
            }
        }
        for (let el of this.fadeItems) {
            let rect = el.getBoundingClientRect();
            if (rect.top > scrollPadding || window.scrollY > height - scrollPadding) {
              return;
            }
            el.classList.add("viewable");
        }
    }

    mounted() {
      this.loadFadeItems();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleScroll);
        this.handleScroll();
    }
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    }

    loadFadeItems() {
      this.fadeItems = Array.from(document.querySelectorAll('.fade-up:not(.viewable)'));
      this.fadeItems.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
    }

    parse(emoji: string) {
      // console.log(twemoji, twemoji.parse);
      // console.log(twemoji.default.parse(emoji));
      return twemoji.default.parse(emoji);
    }
}
