













































import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Navbar extends Vue {
    private opened: boolean = false;

    toggleBurger() {
        this.opened = !this.opened;
    }
}
