






















































import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';
import HeadSection from './components/HeadSection.vue';
import Navbar from './components/Navbar.vue';
import Stats from './components/Stats.vue';
import Features from './components/Features.vue';

interface StatsObject {
  serverCount: number;
  shards: Array<any>;
  messageCount: number;
  userCount: number;
  activePlayers: number;
  big_servers: number;
  commandCount: number;
  status: string;
  uptime: number;
}

const fetchTime = 5000;

@Component({
  components: {
    HeadSection,
    Navbar,
    Stats,
    Features,
  },
})
export default class App extends Vue {
  stats: StatsObject = {
    serverCount: 0,
    shards: [],
    messageCount: 0,
    userCount: 0,
    activePlayers: 0,
    big_servers: 0,
    commandCount: 0,
    status: 'OK',
    uptime: 0,
  };

  async getStats() {
    try {
      const { status, data } = await axios({
        url: process.env.VUE_APP_API_URL,
        method: 'GET',
      });
      if (status === 200 && data && data.status === 'OK') {
        this.stats = {
          ...data,
          messageCount: 175_000_000,
          commandCount: 900_000,
        };
      }
    } catch(e) {
      if (e.response) {
        const { status, data } = e.response;
        console.error("Error grabbing bot stats", status, data);
        return;
      }
      console.error("Error grabbing bot stats", e);
    } finally {
      setTimeout(this.getStats, fetchTime);
    }
  }

  mounted() {
    this.getStats();
  }
}
