































import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import TWEEN from '@tweenjs/tween.js';

const updateTime = 800;

interface StatsObject {
    serverCount: number;
    shards: Array<any>;
    messageCount: number;
    userCount: number;
    activePlayers: number;
    big_servers: number;
    commandCount: number;
    status: string;
    uptime: number;
    shardCount: number;
}

interface StatsObjectNoShardCount {
    serverCount: number;
    shards: Array<any>;
    messageCount: number;
    userCount: number;
    activePlayers: number;
    big_servers: number;
    commandCount: number;
    status: string;
    uptime: number;
}

const StatsProps = Vue.extend({
    props: {
        stats: {
            type: Object as PropType<StatsObjectNoShardCount>,
            required: true,
            validator (stats: StatsObject) {
                return stats.status === 'OK';
            }
        }
    }
})

@Component<Stats>({
    watch: {
        stats: {
            deep: true,
            handler(newStats: StatsObjectNoShardCount) {
                this.tweenObject = new TWEEN.Tween(this.statsDisplay as any);
                this.tweenObject.to({shardCount: this.stats.shards.length, ...newStats}, updateTime);
                this.tweenObject.start();
                this.$forceUpdate();
            }
        }
    }
})
export default class Stats extends StatsProps {
    tweenObject?: any = undefined;

    statsDisplay: StatsObject = {
        serverCount: 0,
        shards: [],
        messageCount: 0,
        userCount: 0,
        activePlayers: 0,
        big_servers: 0,
        commandCount: 0,
        status: 'OK',
        uptime: 0,
        shardCount: 0,
    };

    get serverCount() {
        let x = this.statsDisplay.serverCount;
        if (x === 0) return '...';
        if (x >= 1000000) {
            let y = Math.floor(x / 100000);
            if (y % 10 == 0) {
                return `${Math.floor(x / 1000000)}M+`;
            }
            return `${(x / 1000000).toFixed(1)}M+`
        }
        if (x >= 1000) {
            return `${Math.floor(x / 1000)}K+`;
        }
        return Math.round(x);
    }

    get userCount() {
        let x = this.statsDisplay.userCount;
        if (x === 0) return '...';
        if (x >= 10000000) {
            let y = Math.floor(x / 100000);
            if (y % 10 == 0) {
                return `${Math.floor(x / 1000000)}M+`;
            }
            return `${(x / 1000000).toFixed(1)}M+`
        } else if (x >= 1000) {
            return `${Math.floor(x / 1000)}K+`;
        }
        return Math.round(x);
    }

    get shardCount() {
        let x = this.statsDisplay.shardCount;
        if (x === 0) return '...';
        if (x >= 1000) {
            return `${Math.floor(x / 1000)}K+`;
        }
        return Math.round(x);
    }

    get bigServers() {
        let x = this.statsDisplay.big_servers || 0;
        if (x === 0) return '...';
        if (x >= 1000) {
            return `${Math.floor(x / 1000)}K+`;
        }
        return Math.round(x);
    }

    get messageCount() {
        let x = this.statsDisplay.messageCount;
        if (x === 0) return '...';
        if (x >= 1000000) {
            return `${Math.floor(x / 1000000)}M+`;
        } else if (x >= 1000) {
            return `${Math.floor(x / 1000)}K+`;
        }
        return Math.round(x);
    }

    get commandCount() {
        let x = this.statsDisplay.commandCount;
        if (x === 0) return '...';
        if (x >= 1000000) {
            return `${Math.floor(x / 1000000)}M+`;
        } else if (x >= 1000) {
            return `${Math.floor(x / 1000)}K+`;
        }
        return Math.round(x);
    }

    mounted() {
        function animate() {
            requestAnimationFrame(animate);
            TWEEN.update(undefined as any);
        }
        animate();

        this.tweenObject = new TWEEN.Tween(this.statsDisplay as any);
        this.tweenObject.to({shardCount: this.stats.shards.length, ...this.stats}, updateTime);
        this.tweenObject.start();
    }
}
